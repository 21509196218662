<script lang="ts">
import { defineAsyncComponent, defineComponent } from 'vue'
// import feIconComponent from '@/components/global/icon.vue'
// import feButton from '@/components/global/button.vue'
import { Keyboard } from '@capacitor/keyboard'
import { isNativeMobileApp } from '@/addons/mobile'
const feButton = defineAsyncComponent(
  () => import('@/components/global/button.vue'),
)
const feIconComponent = defineAsyncComponent(
  () => import('@/components/global/icon.vue'),
)
const FeReturnsPayments = defineAsyncComponent(
  () => import('@/components/modal/returns-payments.vue'),
)
const FeToolsAdvanceModalInput = defineAsyncComponent(
  () => import('@/components/modal/tools-advance-modal-input.vue'),
)
const FeTaxfreeError = defineAsyncComponent(
  () => import('@/components/recap/fattura/modal/taxfree-error.vue'),
)
const FeModalCreditCard = defineAsyncComponent(
  () => import('@/components/returns/partials/modal-credit-card.vue'),
)
// const LoaderController = defineAsyncComponent(
//   () => import('@/components/loader/LoaderController')
// )
import LoaderController from '@/components/loader/LoaderController'

const FeModalDisableCard = defineAsyncComponent(
  () => import('@/modules/loyalty/components/modals/disable-card.vue'),
)
const FeModalTransferPoints = defineAsyncComponent(
  () => import('@/modules/loyalty/components/modals/transfer-points.vue'),
)
// const FeModalStepper = defineAsyncComponent(
//   () => import('@/components/stepper/FeStepper.vue')
// )

import FeModalPicking from '@/modules/picking/modal-picking/modal-picking.vue'
import FeFlingModalComponent from '@/modules/endless-aisle/components/product-info/fling/fling-modal-component/fling-modal-component.vue'
export default defineComponent({
  name: 'FeModal',
  components: {
    feButton,
    'fe-icon': feIconComponent,
    FeModalCreditCard,
    FeReturnsPayments,
    FeTaxfreeError,
    FeToolsAdvanceModalInput,
    FeModalDisableCard,
    FeModalTransferPoints,
    // FeModalStepper,
    // LoaderController,
    FeModalPicking,
    FeFlingModalComponent,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['modal'],
  emits: ['returnTransaction', 'handleConfirmClick', 'close'],
  computed: {
    bindProps() {
      return {
        ...this.modal.customProps,
        customEventHandlers: this.modal.customEventHandlers,
        customProps: this.modal.customProps,
        payments: this.modal.customProps?.payments,
      }
    },
  },
  created() {
    if (isNativeMobileApp()) {
      Keyboard.hide()
    }
  },
  methods: {
    close() {
      LoaderController.hide()
      this.$emit('close')
    },
    handleConfirmClick() {
      this.$emit('handleConfirmClick')
    },
  },
})
</script>

<template>
  <Teleport :to="modal.teleportTo" :disabled="!modal.teleportTo">
    <div class="fe-modal">
      <div v-if="modal" class="overlay">
        <div
          class="modal"
          :class="[
            modal.customClass || '',
            modal.type || '',
            { [!modal.customFooter]: '--no-footer' },
          ]"
          :data-test="modal.name"
        >
          <component
            :is="modal.component"
            v-if="modal.type === 'modal-stepper'"
            :messages="modal.taxfreeMessages"
            v-bind="bindProps"
            @close="close"
          />
          <template v-else>
            <header class="header">
              <p class="header__title">{{ modal.title }}</p>

              <fe-button
                v-if="modal.closeIcon === true"
                color="icon"
                class="header__close"
                @click="close"
              >
                <fe-icon class="header__icon" icon="close" />
              </fe-button>
            </header>
            <!-- v-html used here because the label contains html tags -->
            <!-- eslint-disable vue/no-v-html -->
            <div class="body">
              <p
                v-if="modal.message"
                :class="'align-' + modal.textAlign"
                v-html="modal.message"
              />
              <div v-if="modal.hasLoader" class="modalLoader-wrapper">
                <div class="modalLoader-container show">
                  <span class="modalLoader generic"></span>
                </div>
              </div>
              <component
                :is="modal.component"
                :messages="modal.taxfreeMessages"
                v-bind="bindProps"
                @close="close"
              />
            </div>

            <footer
              v-if="!modal.customFooter"
              class="footer"
              :class="'align-' + modal.buttonAlign"
            >
              <fe-button
                v-if="!modal.noCancelButton"
                class="with_border"
                data-test="modal__cancel__button"
                :color="modal.customButtons?.close?.color ?? 'border'"
                :class="`${modal.confirmActionButton ? 'close-btn' : ''} ${
                  modal.borderButtons
                }`"
                :fill="modal.customButtons?.close?.fill ?? 'outline'"
                @click="close"
              >
                <span>{{
                  modal.customButtons?.close?.label
                    ? $t(modal.customButtons?.close?.label)
                    : $t('pos_common.cancel')
                }}</span>
                <template #icon-right>
                  <fe-icon
                    :icon="modal.customButtons?.close?.icons ?? 'close'"
                  />
                </template>
              </fe-button>

              <fe-button
                v-if="modal.confirmActionButton"
                :color="modal.customButtons?.confirm?.color ?? 'border'"
                :fill="modal.customButtons?.confirm?.fill ?? 'outline'"
                class="confirm-btn with_border"
                data-test="modal__confirm__button"
                @click="handleConfirmClick"
              >
                <span>{{
                  modal.customButtons?.confirm?.label
                    ? $t(modal.customButtons?.confirm?.label)
                    : modal.confirmLabel
                }}</span>
                <template #icon-right>
                  <fe-icon
                    :icon="
                      modal.customButtons?.confirm?.icons ?? 'chevron-right'
                    "
                  />
                </template>
              </fe-button>
            </footer>
          </template>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss">
@import '@/styles/_constants.scss';
@import '@/styles/_mixins.scss';

.fe-modal {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  .overlay {
    @include z-index('modal');
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba($color-base, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 39;
    position: absolute;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
  }

  .modal {
    transition: all 0.3s ease-in-out;
    display: grid;
    grid-template-rows: minmax(min-content, max-content) 1fr;
    width: 53.2rem;
    box-shadow: -1.6rem 1.8rem 2.4rem rgba($color-black, 0.05);

    &.delivery-modal {
      width: 82.3rem;
    }
    &.picking-modal {
      min-height: 28.5rem;
    }
    &.--no-footer {
      .body {
        border-radius: 0 0 0.8rem 0.8rem;
      }
    }
    &.modal-warning {
      .header {
        background: $color-alert-main;
      }
    }

    &.prints-errors,
    &.modal-error {
      .header {
        background: $color-error;
      }
    }
    &.modal-black {
      .header {
        background: $color-black;
      }
      .body {
        border-radius: 0 0 0.8rem 0.8rem;
      }
    }
    &.modal-stepper {
      width: auto;
    }

    .body {
      padding-top: 1rem;
    }

    &.returns-payments {
      // custom class for fe-returns-payments
      width: 87rem;

      .body p {
        text-align: center;
        color: $color-base;
      }
    }
    &.user_modal {
      justify-content: center;
      width: 36.2rem;
    }
    &.abs-crm-modal {
      width: 38rem;
      p {
        font-size: 1.4rem;
      }
      .footer {
        padding: 0.5rem 3rem 1.8rem 3rem;
      }
      .confirm-btn {
        --background: #{$color-selected-light};
        border: none;
      }
    }
  }

  .header {
    background: $color-selected-light;
    color: $color-white;
    padding: 0 2rem;
    box-sizing: border-box;
    grid-row: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.8rem 0.8rem 0 0;
    min-height: 4rem;

    &__title {
      text-transform: uppercase;
      margin: 0;
      font-weight: 600;
      font-size: 1.6rem;
    }

    &__icon ion-icon {
      fill: white !important;
    }
  }

  .body {
    grid-row: 2;
    padding: 0 2rem;
    background: $color-light-grey;

    p.align-center {
      text-align: center;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    border-radius: 0 0 0.8rem 0.8rem;
    padding: 0.5rem 2rem 1.8rem 2rem;
    background: $color-light-grey;

    &.align-center {
      justify-content: center;
    }

    & > .close-btn {
      margin-right: 0.5rem;
    }

    & > * {
      --border-style: none;
    }
  }

  .with_border {
    &.info {
      border: none;
    }
    border: 1px solid var(--border-color);
  }
  .alipay-modal {
    .body {
      border-bottom-left-radius: 0.8rem;
      border-bottom-right-radius: 0.8rem;
    }
  }
}
</style>
